import { FC } from 'react';
import Index from '../../components/Index';

interface HomeViewProps {
    showHome: boolean;
    showTokenList: boolean;
    showCreateToken: boolean;
    showInvite: boolean;
    showLeaderboard: boolean;
    handleHomeClick: () => void;
    handleLaunchClick: () => void;
    handleInviteClick: () => void;
    handleTokenClick: () => void;
    handleLeaderboardClick: () => void;
}

export const HomeView: FC<HomeViewProps> = ({
    showHome,
    showTokenList,
    showCreateToken,
    showInvite,
    showLeaderboard,
    handleHomeClick,
    handleLaunchClick,
    handleInviteClick,
    handleTokenClick,
    handleLeaderboardClick
}) => {
    return (
        <Index
            showHome={showHome}
            showTokenList={showTokenList}
            showCreateToken={showCreateToken}
            showInvite={showInvite}
            showLeaderboard={showLeaderboard}
            handleHomeClick={handleHomeClick}
            handleLaunchClick={handleLaunchClick}
            handleInviteClick={handleInviteClick}
            handleTokenClick={handleTokenClick}
            handleLeaderboardClick={handleLeaderboardClick}
        />
    );
};
