import { NextPage } from 'next';
import { HomeView } from '../views';
import { NextSeo } from 'next-seo';

interface HomeProps {
  showHome: boolean;
  showTokenList: boolean;
  showCreateToken: boolean;
  showInvite: boolean;
  showLeaderboard: boolean;
  handleHomeClick: () => void;
  handleLaunchClick: () => void;
  handleInviteClick: () => void;
  handleTokenClick: () => void;
  handleLeaderboardClick: () => void;
}

const Home: NextPage<HomeProps> = ({
  showHome,
  showTokenList,
  showCreateToken,
  showInvite,
  showLeaderboard,
  handleHomeClick,
  handleLaunchClick,
  handleInviteClick,
  handleTokenClick,
  handleLeaderboardClick
}) => {
  return (
    <>
      <NextSeo
        title="MRKT"
        description="Onchain pvp marketplace."
        openGraph={{
          url: 'https://mrkt.fun',
          type: 'website',
          locale: 'en_IE',
          title: 'MRKT',
          description: 'Onchain pvp marketplace.',
          images: [
            {
              url: 'https://firebasestorage.googleapis.com/v0/b/vula-labs-loyalty.appspot.com/o/emojis%2FIMG_0891.jpg?alt=media&token=bec8a094-5398-4d71-b39f-1487063c291e',
              width: 1200,
              height: 630,
              alt: 'MRKT Header Image',
              type: 'image/png',
            },
          ],
          siteName: 'MRKT',
        }}
      />
      <HomeView
        showHome={showHome}
        showTokenList={showTokenList}
        showCreateToken={showCreateToken}
        showInvite={showInvite}
        showLeaderboard={showLeaderboard}
        handleHomeClick={handleHomeClick}
        handleLaunchClick={handleLaunchClick}
        handleInviteClick={handleInviteClick}
        handleTokenClick={handleTokenClick}
        handleLeaderboardClick={handleLeaderboardClick}
      />
    </>
  );
};

export default Home;
